import { Injectable, Inject } from "@angular/core";
import { APP_CONFIG, AppConfig, AppHostService } from "app/core";
import { UserStore } from "app/user";
import { ConversationPrefsStore } from "app/chat/prefs";
import {
  AcceptedSuggestionOutgoingMessage,
  ConversationMetadata,
  EvaluationOutgoingMessage,
  EvaluationResult,
  InitializationOutgoingMessage,
  IntentionOutgoingMessage,
  MessageMinimalMetadata,
  SatisfactionCommentOutgoingMessage,
  SatisfactionLevelOutgoingMessage,
  TextOutgoingMessage,
} from "./outgoing-message.model";
import { USER_STORE } from "app/user/di-tokens";
import { SatisfactionEmoji, Suggestion } from "../incoming";

/** Factory for messages to be sent to the server */
@Injectable({ providedIn: "root" })
export class OutgoingMessageFactory {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private hostService: AppHostService,
    @Inject(USER_STORE) private userStore: UserStore,
    private conversationPrefsStore: ConversationPrefsStore
  ) {}

  private makeMinimalMetadata(): MessageMinimalMetadata {
    return {
      bot_name: this.hostService.isTestBot ? "test" : this.config.botVersion,
      device: this.hostService.deviceType,
      href: this.hostService.currentUrl,
    };
  }
  private makeConversationMetadata(): ConversationMetadata {
    const { token } = this.userStore.get();
    return {
      ...this.makeMinimalMetadata(),
      token,
      delete_message: !this.conversationPrefsStore.get().keepOnServer,
    };
  }

  public makeInitializationMessage(): InitializationOutgoingMessage {
    return {
      content_type: "postback",
      content: { intent: "messenger_entrypoint", isOutgoing: true },
      extra: { ...this.makeMinimalMetadata(), alternative: !!this.config.scenario },
    };
  }

  public makeTextMessage(text: string): TextOutgoingMessage {
    return {
      content_type: "text",
      content: { value: text, isOutgoing: true },
      extra: this.makeConversationMetadata(),
    };
  }

  public makeIntentionMessage(intention: string): IntentionOutgoingMessage {
    return {
      content_type: "postback",
      content: { intent: intention, isOutgoing: true },
      extra: this.makeConversationMetadata(),
    };
  }

  public makeAcceptedSuggestionMessage(suggestion: Suggestion) {
    let result: AcceptedSuggestionOutgoingMessage = {
      content_type: "postback",
      content: {
        text: suggestion.text,
        intent: suggestion.intent,
      },
      extra: this.makeConversationMetadata(),
    };

    if (suggestion.entities) {
      result.content.entities = suggestion.entities;
    }
    if (suggestion.script) {
      result.content.script = suggestion.script;
    }
    if (suggestion.color) {
      result.content.color = suggestion.color;
    }
    return result;
  }

  public makeSatisfactionEmojiMessage(emoji: SatisfactionEmoji) {
    let result: SatisfactionLevelOutgoingMessage = {
      content_type: "postback",
      content: { text: emoji.text, intent: emoji.redir },
      extra: this.makeConversationMetadata(),
    };
    if (emoji.type) {
      result.extra.satisfaction_emoji = emoji.type;
    }
    return result;
  }

  public makeSatisfactionCommentMessage(
    text: string,
    intention: string
  ): SatisfactionCommentOutgoingMessage {
    return {
      content_type: "postback",
      content: { value: text, text, intent: intention, isOutgoing: true },
      extra: {
        ...this.makeConversationMetadata(),
        satisfaction_user_comment: "true",
        user_comment_text: text,
      },
    };
  }

  public makeEvaluationOutgoingMessage(evaluation: EvaluationResult): EvaluationOutgoingMessage {
    return {
      type: evaluation.is_user_evaluation_positive ? "positive" : "negative",
      reason_key: evaluation.negative_explanation?.key,
      reason_text: evaluation.negative_explanation?.text,
    };
  }
}
