import { BotName, CompilationConfig } from "../models/config.model";
import { commonConfig } from "./common.config";

export const popConfig: CompilationConfig = {
  ...commonConfig, // Default values

  botName: BotName.POP,

  hostDimensions: {
    openHeight: 650,
    openWidth: 450,
    closedHeight: 150,
    closedWidth: 200,
    closedSmallScreenHeight: 100,
    closedSmallScreenWidth: 130,
  },
};
