import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG } from "../config";
import { AppConfig } from "../config/config.model";
import { EventBus } from "../events/event-bus.service";

/** A service used to interact with the parent iframe */
@Injectable({ providedIn: "root" })
export class AppParentService {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private eventBus: EventBus
  ) {}

  /** Send a message to the parent container */
  postMessageToParent(eventId: string, value: any = null) {
    this.eventBus.sendEventMessage({ eventId, data: value });
  }

  /** Force the display of the bot in the parent container */
  public setBotVisible(visible: boolean): void {
    this.postMessageToParent("botToggled", visible);
  }

  /** Remove this bot from the parent container */
  public removeBot(): void {
    this.postMessageToParent("remove");
  }

  /** Request the parent container to display a popup with an image denoted by the passed URL */
  public showImagePopup(url: string): void {
    this.postMessageToParent("image", url);
  }

  public getLocalStorageItem(key: string): void {
    this.postMessageToParent("get_" + key);
  }

  public setLocalStorageItem(key: string, data: any): void {
    this.postMessageToParent("set_" + key, data);
  }
}
