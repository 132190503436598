import { EnvironmentInjector, InjectionToken, inject, runInInjectionContext } from "@angular/core";
import { timer } from "rxjs";
import { getConfig } from "../../../../loader-factory/src/services/config";
import { environment } from "../../../environments/environment";
import { specificConfig } from "./config-specific-bot";
import { AppConfig, RunningConfig } from "./config.model";
import { BackendConfigApiClient } from "./connector/backend-config.apiclient";

/**
 * Launches an asynchronous task that will retrieve the backend config and update the passed config
 * with the data retrieved from the server
 */
function asyncRetrieveBackendConfig(config: AppConfig) {
  const environmentInjector = inject(EnvironmentInjector);
  timer(0).subscribe(() =>
    runInInjectionContext(environmentInjector, () => {
      const apiClient = inject(BackendConfigApiClient);
      apiClient.retrieveConfig(config.backendBaseUrl + "ui-config/").subscribe({
        next: (backendConfig) => {
          if (config.headerBanner) {
            config.headerBanner.text.set(backendConfig.banner_txt);
          }
        },
        error: (err) => console.error(`Unable to retrieve backend config, ${err}`),
      });
    })
  );
}

function convertToExpectedType(config: Partial<RunningConfig>) {
  /*Convert boolean*/
  if (config) {
    for (const [key, value] of Object.entries(config)) {
      if (value === "True" || value === "true") {
        (config[key as keyof RunningConfig] as boolean) = true;
      } else if (value === "False" || value === "false") {
        (config[key as keyof RunningConfig] as boolean) = false;
      }
    }
  }
  /*Convert numbers*/
  if (config?.delayInConversationMessagesDisplayInMs) {
    config["delayInConversationMessagesDisplayInMs"] = Number(
      config.delayInConversationMessagesDisplayInMs
    );
  }
  return config;
}

export const APP_CONFIG = new InjectionToken<AppConfig>("app.config", {
  factory: () => {
    //Get compilation config from loader-factory
    const compilationConfig = getConfig(environment.production);

    //Get running config for production from docker-entrypoint
    const prodRunningConfig = convertToExpectedType(
      // Variable d'environnement en production
      (window as any)["BOT_WEB_INTERFACE_SETTINGS"]
    );

    const config = {
      ...compilationConfig, // compilation config from loader-factory
      ...environment, // environnment is the common running config + production status (same for all bots)
      ...specificConfig[compilationConfig.botName], // specific config for the running bot
      ...prodRunningConfig, //overwrite with production env variables.
    };
    if (config.hasConfigurationFromBackend) {
      asyncRetrieveBackendConfig(config);
    }
    return config;
  },
});

export { AppConfig, HeaderActionType } from "./config.model";
