import { BotName, CompilationConfig } from "../models/config.model";
import { commonConfig } from "./common.config";

export const digiConfig: CompilationConfig = {
  ...commonConfig, // Default values

  botName: BotName.DIGI,
  iframeSandbox: true,

  hostDimensions: {
    openHeight: 650,
    openWidth: 450,
    closedHeight: 125,
    closedWidth: 175,
    closedSmallScreenHeight: 80,
    closedSmallScreenWidth: 80,
  },
};
