// Loaded by each bot

import { RunningConfig } from "app/core/config/config.model";

// Default config variables. Can be overriden by config-specific-bot or config-dev-only.
export const commonRunningConfig: RunningConfig = {
  production: true,
  lang: "fr",
  backendBaseUrl: "https://ui-api.arolebot-prod.probayes.net/v0/",
  distLocation: "",
  scenario: "",

  checkAvailability: false,
  hasMaintenance: false,
  whereToAlwaysShow: "",

  headerActions: [],
  headerLogoUrl: "",
  messageAvatarUrl: "",

  reducedBotHasWelcomeText: false,
  reducedBotLogoUrl: "",
  hasCustomButtonColor: false,
  hasResetConversationOnReload: false,
  hasEvaluationWithStars: false,

  shouldAskToKeepConversation: false,
  dimeloLoaderUrl: "",
  dimeloTriggerId: "",
  dimeloNeedsPersonalInfo: false,
  delayInConversationMessagesDisplayInMs: 3500,
  hasConfigurationFromBackend: false,
  convertEmojisToImages: false,

  scrollOnMessages: true,
  //* !if changed, check there is no regression on screen reader behavior and also on auto scroll on image or ending buttons*/
  focusRequestsDelay: 300,
  currentBlockAriaVisibleAfter: 350,
};
