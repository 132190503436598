import { BotName, CompilationConfig } from "../models/config.model";

// TODO: Charger en prod uniquement la config pour le client concerné (variable d'environnement)

export const commonConfig: CompilationConfig = {
  botName: BotName.AROLE,
  distLocation: "http://localhost:4200",
  iframeSandbox: false,
  botVersion: "",
  localStoragePrefix: "",
  opened: false,

  restrictedUriPatterns: ["MyTalentsoft"],

  hostDimensions: {
    openHeight: 650,
    openWidth: 450,
    closedHeight: 180,
    closedWidth: 130,
    closedSmallScreenHeight: 100,
    closedSmallScreenWidth: 100,
  },

  hasOidcSupport: false,
};
