import { signal } from "@angular/core";
import { RunningConfig } from "./config.model";

type SpecificConfig = {
  [key: string]: Partial<RunningConfig>;
};

// Bot specific configuration
// ! Be careful, some of this variables can be overwrite with docker-entrypoint on production
export const specificConfig: SpecificConfig = {
  clickrh: {
    backendBaseUrl: "https://ui-api.chatbotrh.laposte.fr/v0/",
    checkAvailability: true,
    hasMaintenance: true,
    hasConfigurationFromBackend: true,
    idrhSupport: { required: true },
    hasResetConversationOnReload: false,

    headerLogoUrl: "/assets/specific/clickrh/avatar.svg",
    headerBanner: { text: signal<string>("") },
    personalDataMessage: {
      text: signal<string>(
        "Nous traitons vos données conformément à notre charte de confidentialité. <strong><a href='https://ui.chatbotrh.laposte.fr/assets/static/pdf/Mentions_RGPD_JUIL2024.pdf' target='_blank'>En savoir plus sur la charte</a> </strong>"
      ),
    },
    reducedBotLogoUrl: "/assets/specific/clickrh/avatar-circled.svg",
    reducedBotHasWelcomeText: true,

    hasCustomButtonColor: true,
    //Click RH has long messages, and if we scroll on messages, we don't have time to read message before it scrolls. That's why clickRH has special scroll on block.
    scrollOnMessages: false,

    messageAvatarUrl: "/assets/specific/clickrh/avatar.svg",

    whereToSetDefaultIdrh: ["localhost", "ui.chatbotrh", "admin.chatbotrh"],
    defaultIdrh: "pxxxxxx",
    defaultIdrhBotTest: "ppppppp",
  },
  pop: {
    backendBaseUrl: "https://ui-api.popbot-prod.probayes.net/v0/",
    headerLogoUrl: "/assets/specific/pop/launcher.png",
    headerLogoAnimations: {
      duration: 4000,
      play_half: true,
      default: {
        path: "/assets/specific/pop/default_animation.json",
        duration: 1500,
      },
    },
    reducedBotLogoUrl: "/assets/specific/pop/bot-icon-circled.svg",
    reducedBotHasWelcomeText: true,
    hasEvaluationWithStars: true,
    shouldAskToKeepConversation: true,
    convertEmojisToImages: true,
    messageAvatarUrl: "/assets/specific/pop/bot-icon.svg",
    delayInConversationMessagesDisplayInMs: 1500,
  },
  arole: {
    backendBaseUrl: "https://ui-api.arolebot-out-prod.probayes.net/v0/",
    headerLogoUrl: "/assets/specific/arole/avatar-ring.svg",
    reducedBotLogoUrl: "/assets/specific/arole/avatar-reduced-bot.svg",
    reducedBotHasWelcomeText: true,
    messageAvatarUrl: "/assets/specific/arole/avatar.svg",
  },
  digi: {
    backendBaseUrl: "https://ui-api.digibot-prod.probayes.net/v0/",
    headerLogoUrl: "/assets/specific/digi/avatar.png",
    headerLogoAnimations: {
      duration: 4000,
      default: {
        path: "/assets/specific/digi/default_animation.json",
        duration: 1500,
      },
    },
    reducedBotLogoUrl: "/assets/specific/digi/chatbot_digiposte_blanc_64x64_JS.svg",
    reducedBotHasWelcomeText: true,
    dimeloNeedsPersonalInfo: true,
    messageAvatarUrl: "/assets/specific/digi/avatar.png",
  },
  douane: {
    backendBaseUrl: "https://ui-api.douanebot-out-prod.probayes.net/v0/",
    headerLogoUrl: "/assets/specific/douane/avatar.svg",
    reducedBotLogoUrl: "/assets/specific/douane/avatar.svg",
    reducedBotHasWelcomeText: true,
    messageAvatarUrl: "/assets/specific/douane/avatar.svg",
  },
  plume: {
    backendBaseUrl: "https://ui-api.plumebot.laposte.fr/v0/",
    headerLogoUrl: "/assets/specific/plume/avatar.png",
    headerActions: ["menu"],
    reducedBotHasWelcomeText: true,
    reducedBotLogoUrl: "/assets/specific/plume/avatar.png",
    idrhSupport: { required: false },
    messageAvatarUrl: "/assets/specific/plume/avatar.png",
  },
  rela: {
    backendBaseUrl: "https:///ui-bo-api.relabot-out-prod.probayes.net/v0/",
    headerLogoUrl: "/assets/specific/arole/avatar-ring.svg",
    reducedBotLogoUrl: "/assets/specific/arole/avatar-reduced-bot.svg",
    reducedBotHasWelcomeText: true,
    messageAvatarUrl: "/assets/specific/arole/avatar.svg",
  },
};
