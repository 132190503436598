import { Injectable } from "@angular/core";
import { EventMessage } from "./event-message.model";

/**
 * An event bus that allows communication between different parts of the application.
 * It uses BroadcastChannel to send messages. The channel name is prefixed with "fof" to avoid conflicts with other applications.
 */
@Injectable({ providedIn: "root" })
export class EventBus {
  // The target iframe element to send messages to.
  private _target: HTMLIFrameElement | null = null;
  // The port used to send messages
  private _port: MessagePort | null = null;

  private _channel: MessageChannel = new MessageChannel();

  // List of subscribers to the event bus
  private _subscribers: Array<(value: EventMessage<any>) => void> = [];

  set target(value: HTMLIFrameElement) {
    this._target = value;
  }

  set port(value: MessagePort) {
    this._port = value;
  }

  /**
   * Initializes the child channel.
   *
   * This allows communication with the child iframe by sending a communication port, allowing the child to send messages back via MessageChannel.
   */
  initChildChannel(): void {
    const init: EventMessage<null> = {
      eventId: "init",
      data: null,
    };
    this._target?.contentWindow?.postMessage(init, "*", [this._channel.port2]);
  }

  /**
   * Waits for the init event to be received.
   *
   * Optionally, a callback can be provided to handle the init event.
   * @param callback - The callback function to be called when the init event is received.
   * @returns A promise that resolves when the init event is received.
   */
  waitForInit(callback?: (event: EventMessage<any>) => void): Promise<void> {
    return new Promise((resolve) => {
      window.addEventListener("message", (event) => {
        if (!event.ports.length) return;

        const message = event.data as EventMessage<any>;
        if (message.eventId === "init") {
          console.log("init event received in app");
          if (!this.port) {
            this.port = event.ports[0];
          }
        }

        if (callback) this.subscribe((message) => callback(message));
        resolve();
      });
    });
  }

  /**
   * Sends a message through the event bus channel.
   * Messages can be structured objects, e.g. nested objects and arrays.
   *
   * @param message - The message to be sent.
   */
  public sendEventMessage<T>(message: EventMessage<T>): void {
    if (this._port) {
      this._port.postMessage(message);
    } else {
      this._channel?.port1.postMessage(message);
    }
  }

  /**
   * Connects to the MessageChannel and subscribes to messages.
   * The callback function is called whenever a message is received.
   *
   * @param channelName - The name of the channel to connect to.
   * @returns The connection to the channel.
   */
  public subscribe<T>(callback: (value: EventMessage<T>) => void): void {
    this._subscribers.push(callback as (value: EventMessage<any>) => void);

    const handler = (event: MessageEvent) => {
      const message = event.data as EventMessage<T>;
      this._subscribers.forEach((subscriber) => subscriber(message));
    };

    if (this._port) {
      this._port.onmessage = handler;
    } else {
      this._channel.port1.onmessage = handler;
    }
  }

  /**
   * Closes all broadcast channels.
   */
  public closeAllChannels(): void {
    if (this._channel) {
      this._channel?.port1.close();
      this._channel?.port2.close();
    } else {
      this._port?.close();
    }
  }
}
