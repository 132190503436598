import { BotName, CompilationConfig } from "../models/config.model";
import { commonConfig } from "./common.config";

export const clickrhConfig: CompilationConfig = {
  ...commonConfig, // Default values

  botName: BotName.CLICKRH,

  hostDimensions: {
    openHeight: 650,
    openWidth: 450,
    closedHeight: 130,
    closedWidth: 180,
    closedSmallScreenHeight: 100,
    closedSmallScreenWidth: 100,
  },

  hasOidcSupport: true,
};
