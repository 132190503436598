import { Inject, Injectable } from "@angular/core";
import { distinctUntilChanged, filter, Observable, tap } from "rxjs";
import { AVAILABILITY_STORE, AvailabilityState, AvailabilityStore } from "./availability";
import { ChatLifecycleService, ChatOpenStore } from "app/chat";
import { AppConfig, AppHostService, AppParentService, APP_CONFIG } from "app/core";

/** A service used to retrieve and interact with all the states of the application */
@Injectable({ providedIn: "root" })
export class AppStateService {
  constructor(
    @Inject(AVAILABILITY_STORE) private availabilityStore: AvailabilityStore,
    @Inject(APP_CONFIG) private config: AppConfig,
    private chatLifecycleService: ChatLifecycleService,
    private parentService: AppParentService,
    private hostService: AppHostService,
    private chatOpenStore: ChatOpenStore
  ) {}

  /** Initializes all the states in the application, for the states that are required */
  public initialize(): void {
    this.availabilityStore.initialize();
    this.chatLifecycleService.initialize();
    if (this.config.iframeSandbox) {
      this.hostService.isHostReady$
        .pipe(
          filter((ready) => ready === true),
          distinctUntilChanged(),
          tap(() => {
            this.parentService.updateHostDimensions();
            this.parentService.setBotVisible(this.chatOpenStore.isOpen);
          })
        )
        .subscribe();
    } else {
      this.parentService.setUpDragAndDrop();
      this.parentService.updateHostDimensions();
      this.parentService.setBotVisible(this.chatOpenStore.isOpen);
    }
  }

  /** @return Whether the application is available for the current user */
  get available$(): Observable<AvailabilityState> {
    return this.availabilityStore.available$;
  }
}
