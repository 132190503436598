import { Inject, Injectable } from "@angular/core";
import { CONVERSATION_LOCK } from "../di-tokens";
import { MessageSender, SatisfactionEmoji, Suggestion } from "app/chat/incoming";
import { OutgoingMessageFactory } from "./outgoing-message.factory";
import { EvaluationResult, OutgoingMessage } from "./outgoing-message.model";
import { LockUtil } from "app/core";

/** Service to send messages to the server */
@Injectable({ providedIn: "root" })
export class OutgoingMessageService {
  constructor(
    private messageSender: MessageSender,
    private outgoingMessageFactory: OutgoingMessageFactory,
    @Inject(CONVERSATION_LOCK) private conversationLock: LockUtil
  ) {}

  private sendMessageWithLocking(message: OutgoingMessage) {
    this.conversationLock.lock();
    this.messageSender.send(message);
  }

  public sendTextMessage(text: string) {
    const message = this.outgoingMessageFactory.makeTextMessage(text);
    this.sendMessageWithLocking(message);
    return message;
  }

  public sendIntentionMessage(intention: string) {
    const message = this.outgoingMessageFactory.makeIntentionMessage(intention);
    this.sendMessageWithLocking(message);
    return message;
  }

  public sendAcceptedSuggestionMessage(suggestion: Suggestion) {
    const message = this.outgoingMessageFactory.makeAcceptedSuggestionMessage(suggestion);
    this.sendMessageWithLocking(message);
    return message;
  }

  public sendEmojiSatisfactionMessage(emoji: SatisfactionEmoji) {
    const message = this.outgoingMessageFactory.makeSatisfactionEmojiMessage(emoji);
    this.sendMessageWithLocking(message);
    return message;
  }

  public sendSatisfactionCommentMessage(text: string, intention: string) {
    const message = this.outgoingMessageFactory.makeSatisfactionCommentMessage(text, intention);
    this.sendMessageWithLocking(message);
    return message;
  }

  public sendEvaluationMessage(evaluation: EvaluationResult) {
    const outgoingEvaluation =
      this.outgoingMessageFactory.makeEvaluationOutgoingMessage(evaluation);
    this.messageSender.sendEvaluation(outgoingEvaluation, evaluation.message_id);
    return outgoingEvaluation;
  }

  public sendEvaluationExplanationsMessage(evaluation: EvaluationResult) {
    const outgoingEvaluation =
      this.outgoingMessageFactory.makeEvaluationOutgoingMessage(evaluation);
    this.messageSender.sendEvaluationExplanations(outgoingEvaluation, evaluation.message_id);
    return outgoingEvaluation;
  }
}
